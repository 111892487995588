<template>
    <a-spin :spinning="spinning">
        <div class="ng-star-inserted">
            <div class="clearfix">
                <div class="box-orderinfo"
                     style="margin-bottom: 20px;">
                    <dl>
                        <dt>
                            <div class="item-text">订单号：{{orderInfo.order_no}}</div>
                            <div class="item-status">{{orderInfo.status_desc}}</div>
                            <div class="item-status self-tag ng-star-inserted">自营</div>
                        </dt>
                        <dd>
                            <ul>
                                <li>订单类型：<span>自营订单</span></li>
                                <li>外部订单号：<span>{{orderInfo.outOrderSn}}</span></li>
                            </ul>
                            <ul>
                                <li>下单方式：<span>开放平台</span></li>
                                <li>下单渠道：<span>????</span></li>
                            </ul>
                            <ul>
                                <li>付款方式：<span>预付款支付</span></li>
                                <li v-if="orderInfo.express_send_at">配送方式：<span>普通物流</span></li>
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
            <a-card title="流程进度">
                <a-steps progress-dot :current="currentStep" labelPlacement="vertical">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title" :description="item.time"/>
                </a-steps>
            </a-card>
            <a-card title="卖家信息">

                <div class="table-wrap ng-star-inserted">
                    <div nzshowpagination="false" class="ant-table-wrapper">
                        <div class="ant-spin-nested-loading">
                            <div class="ant-spin-container ng-star-inserted">
                                <div class="ant-table">
                                    <div class="ant-table-container ng-star-inserted">
                                        <div class="ant-table-content">
                                            <table nz-table-content="" style="table-layout: auto;">
                                                <thead class="ant-table-thead ng-star-inserted">
                                                <tr class="ant-table-row ng-star-inserted">
                                                    <th class="ant-table-cell">收件人</th>
                                                    <th class="ant-table-cell">联系电话</th>
                                                    <th class="ant-table-cell">收货地址</th>
                                                </tr>
                                                </thead>
                                                <tbody class="ant-table-tbody ng-star-inserted">
                                                <tr class="ant-table-row">
                                                    <td class="ant-table-cell">{{orderInfo.express_name}}</td>
                                                    <td class="ant-table-cell">15800019709</td>
                                                    <td class="ant-table-cell">
                                                        {{orderInfo.express_province}}{{orderInfo.express_city}}{{orderInfo.express_area}}{{orderInfo.express_address}}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a-card>

            <div class="box-logistics-wrap" v-if="orderInfo.express_send_at">
                <a-card title="物流信息">
                    <div class="box-logistics ng-star-inserted">
                        <div class="cell-info ng-star-inserted">
                            <div>物流单号：{{orderInfo.express_send_no}}
                                <a-button size="small"  @click="onCopy(orderInfo.express_send_no)">复制</a-button>
                            </div>
                            <div>物流公司：{{orderInfo.express_company_title}}</div>
                            <div>发货时间：{{orderInfo.express_send_at}}</div>
                        </div>
                        <div class="cell-log ng-star-inserted" v-if="orderInfo.express_data.length > 0">
                            <div style="display: flex;">
                                <div>
                                    <div nzmode="default"
                                         class="ng-tns-c228-11 ng-trigger ng-trigger-fadeMotion ant-tag ant-tag-checkable-checked">
                                        最新
                                    </div>
                                </div>
                                <div>
                                    <p>时间：{{orderInfo.express_data[0].time}}</p>
                                    <p style="margin: auto;">
                                        {{orderInfo.express_data[0].context}}
                                        <span style="color: #de0000; cursor: pointer;" @click="() => {showExpressDetailModal = true;}">详情</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-card>
            </div>
            <a-card title="商品信息">
                <a-table :columns="goodsColumns" :data-source="orderInfo.orderList" :pagination="false">
                    <div slot="goods_name" slot-scope="text, record">
                        <div class="service-info">
                            <div style="display: flex; flex: 1;">
                                <img :src="record.goods_logo">
                                <dl style="margin-bottom: 0;">
                                    <dt>{{record.goods_title}}</dt>
                                    <dd style="margin-bottom: 0;" class="ng-star-inserted">规格：{{record.goods_spec}}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div slot="subtotal" slot-scope="text, record">
                        ￥{{parseFloat(record.price_real).toFixed(2)}}
                    </div>
                    <template slot="footer">
                        <div
                                class="order-total-info ng-star-inserted">
                            <ul>
                                <li>商品总额：￥{{orderInfo.price_goods}}</li>
                                <li>运费：￥{{orderInfo.price_express}}</li>
                                <li>实付款：<span class="text-red">￥{{orderInfo.price_total}}</span></li>
                            </ul>
                        </div>
                    </template>
                </a-table>
            </a-card>
        </div>
        <a-modal title="运单详情" :visible="showExpressDetailModal" width="600px" :footer="null" @cancel="() => {showExpressDetailModal = false;}">
            <div>
                <div style="display: flex; justify-content: space-between; background-color: #f7f7f7; font-size: 12px; padding: 12px 6px;">
                    <div>物流单号：{{orderInfo.express_send_no}}</div>
                    <div>物流公司：{{orderInfo.express_company_title}}</div>
                    <div>发货时间：{{orderInfo.express_send_at}}</div>
                </div>
                <div class="cell-log">
                    <a-row style="padding: 12px 6px 0;" v-for="(item, idx) in orderInfo.express_data" :key="idx">
                        <a-col :span="8">{{item.time}}</a-col>
                        <a-col :span="16" style="display: flex;">
                            <div class="item-i">
                                <a-icon type="check-circle" theme="filled" v-if="idx==0" style="    color: #52c41a;"></a-icon>
                                <div v-else style="display: block; width: 8px; height: 8px; border-radius: 50%; background-color: #999999; margin: 4px auto 0;"></div>
                            </div>
                            <dl style="margin-left: 8px;"><dt >{{item.context}}</dt><dd ></dd></dl>
                            </a-col>
                    </a-row>
                </div>
            </div>
        </a-modal>
    </a-spin>
</template>

<script>

    import service from "../../../utils/request";

    export default {
        name: "order-detail",
        components: {},
        data() {
            return {
                showExpressDetailModal: false,
                spinning: false,
                orderInfo: {},

                currentStep: 2,
                steps: [],
                goodsColumns:[
                    {title:'商品', dataIndex:'goods_name', key:'goods_name', scopedSlots: { customRender: 'goods_name' },},
                    {title:'状态', dataIndex:'status', key:'status'},
                    {title:'单价', dataIndex:'price_real', key:'price_real'},
                    {title:'数量', dataIndex:'number_goods', key:'number_goods'},
                    {title:'小计(元)', dataIndex:'subtotal', key:'subtotal', scopedSlots: { customRender: 'subtotal' },},
                ]
            }
        },
        created() {
            this.getInfo();
        },
        methods: {
            onCopy(value) {
                navigator.clipboard.writeText(value).then(() => {
                    this.$message.success('复制成功')
                })
            },
            getInfo() {
                this.spinning = true;
                service.post(service.uri.order.getInfo, {id: this.$route.params.id, type: this.$route.params.type}).then(res => {
                    if (res.code == 200) {
                        this.orderInfo = res.data;
                        this.steps = this.orderInfo.flow;
                        this.currentStep = this.steps.length
                        this.spinning = false;
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .body {
        background: #fbfbfb;
    }

    .page-wrap {
        width: 1200px;
        margin: 0 auto;
    }

    .self-tag {
        margin-left: 10px;
    }

    dt {
        font-weight: 500;
    }

    .box-orderinfo {
        float: left;
        width: 100%;
        padding: 22px 30px 30px;
        box-sizing: border-box;
        background-color: #fff;

        dl, dt, dd, ul, li {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        dl {
            dt {
                display: flex;
                align-items: center;
                padding-bottom: 6px;

                .item-text {
                    flex: 1;
                    font-size: 20px;
                    line-height: 32px;
                    color: #000;
                    font-weight: 500;
                    color: rgba(0, 0, 0, .85);
                }

                .item-status {
                    text-align: center;
                    width: 75px;
                    height: 32px;
                    line-height: 32px;
                    background-color: #de0000;
                    color: #fff;
                }
            }

            dd {
                font-size: 12px;

                ul {
                    display: flex;

                    li {
                        flex: 1;
                        line-height: 22px;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, .85);

                        span {
                            color: rgba(0, 0, 0, .65);
                        }
                    }
                }
            }
        }

    }


    .ant-table {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, .65);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        z-index: 0;
        clear: both;
        background: #fff;
        border-radius: 2px;



        .service-info {
            display: flex;
            align-items: center;
            justify-content: center;

            .col-img, img {
                display: block;
                width: 60px;
                height: 60px;
            }
        }
    }

    .order-total-info {
        text-align: right;
        padding: 10px 0;
        font-size: 14px;
        line-height: 24px;

        ul {
            list-style: none;
        }

        .text-red {
            color: #de0000;
        }
    }

    .text-red {
        color: #f5222d !important;
    }

    .box-logistics-wrap {
        .ant-card-body {
            padding: 10px 20px;
        }

        .box-logistics {
            width: 100%;
            /*height: 290px;*/
            background-color: #fff;
            padding: 0;
            font-size: 12px;
            overflow: auto;

            .cell-info {
                display: flex;
                padding: 10px 33px;
                align-items: center;
                justify-content: space-between;
                font-weight: 500;
                color: rgba(0, 0, 0, .85);
                font-size: 14px;
                background: #fafafa;

                .ant-btn-primary {
                    height: 22px;
                    line-height: 21px;
                    background: #fff;
                    font-size: 12px;
                    color: #666;
                    border: 1px solid #c1c1c1;
                    border-radius: 4px;
                }
            }

            .cell-log {
                max-height: 220px;
                background-color: #f7f7f7;
                padding: 20px 22px;

                .ant-tag-checkable-checked {
                    background-color: #de0000;
                }

                .ant-tag-checkable-checked, .ant-tag-checkable:active {
                    color: #fff;
                }
            }
        }
    }

</style>